export const defaultReferralSources = [
  "Radio",
  "Podcast",
  "Social Media",
  "Email",
  "RefNet",
  "Tabletalk",
  "Ligonier.org",
  "Oneplace",
  "NRB TV",
  "Friend",
  "Other"
]

export const referralSources = {
  rym: [
    "Ligonier App",
    "RenewingYourMind.org",
    "Podcast",
    "RefNet",
    "My Local Radio Station",
    "Other"
  ]
}

export function getReferralSources(brand) {
  return referralSources[brand] || defaultReferralSources
}
