import { addMonths, format, getDate } from "date-fns"
import { Campaign, CampaignLanguageEnum, DateRange, Product } from "../types"
import { countries } from "./support/countries"

export const minimumMinistryPartnerDonation = 30
export const defaultOneTimeGivingAmount = minimumMinistryPartnerDonation
export const defaultMonthlyGivingAmount = minimumMinistryPartnerDonation

/** STRING HELPERS */
export function capitalize(string: string): string {
  return string.replace(/\b[a-z]/g, function (match) {
    return match.toUpperCase()
  })
}

export function slugize(string: string): string {
  return string
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/[^\w\-]+/g, "") // Remove all non-word chars
    .replace(/\-\-+/g, "-") // Replace multiple - with single -
    .trim()
}

/** SRING HELPERS END */

/** ARRAY HELPERS */

export function toReadable(arr, truncateAfter?, using?) {
  var str = ""

  if (arr.length === 1) {
    str = arr[0]
  } else if (arr.length === 2) {
    str = arr.join(" and ")
  } else if (arr.length > truncateAfter) {
    str =
      arr.slice(0, -(arr.length - truncateAfter)).join(", ") + ", and " + using
    //            str = this.slice(0, -(this.length - truncateAfter)).join(', ') + ', and ' + using + '…';
  } else if (arr.length > 2) {
    str = arr.slice(0, -1).join(", ") + ", and " + arr.slice(-1)
  }

  return str

  //      []                   --> ""
  //      ["a"]                --> "a"
  //      ["a","b"]            --> "a and b"
  //      ["a","b","c"]        --> "a, b, and c"
  //      ["a","b","c","d"]    --> "a, b, c, and d"
}

/** ARRAY HELPERS END */

function isEbook(str: string): boolean {
  return /ebook|mobi|epub/gi.test(str)
}

function isBundle(campaign: Campaign) {
  return campaign.offer && campaign.offer.products.length > 1
}

function getProductFormat(format: string): string {
  if (format) {
    switch (format.toLowerCase()) {
      case "dvd":
      case "cd":
      case "epub ebook":
      case "mobi ebook":
        return format
      case "usb drive":
        return "USB drive"
      case "audiobook cd":
        return "audiobook CD"
      case "mp3 cd":
        return "MP3 CD"
      default:
        return format.toLowerCase()
    }
  } else {
    return ""
  }
}

function getProductType(type: string): string {
  if (type) {
    switch (type.toLowerCase()) {
      case "conference messages":
      case "teaching series":
        return "series"
      default:
        return type.toLowerCase()
    }
  } else {
    return ""
  }
}

function getProductVariationFormats(product) {
  return product.variations
    .map(function (variation, v) {
      return getProductFormat(variation.format).replace(/ ebook/gi, "")
    })
    .join(" | ")
}

function getProductFormatAndType(product) {
  var format = getProductFormat(product.format)
  var type = getProductType(product.type)

  if (product.variations) {
    var formats = getProductVariationFormats(product)
    return isEbook(product.variations[0].format) ? "ebook" : "(" + formats + ")"
    //     This might need to come back where different types/formats are handled uniquely
    //     : type + ' (' + formats + ')';
  }

  if (isEbook(format)) {
    return product.format
  } else if (/usb drive/gi.test(format)) {
    return type + " on " + format
  } else if (/cd/gi.test(format)) {
    return type + " " + format
  } else {
    return format + " " + type
  }
}

export function getLiveDateRange(dates: DateRange[]): DateRange | null {
  const now = new Date()
  let rangeIndex = null

  const inRange = dates?.some(function (range, index) {
    const starts = new Date(range.starts)
    const ends = new Date(range.ends)
    const active = now >= starts && now <= ends
    if (active === true) {
      rangeIndex = index
    }
    return active
  })

  return inRange ? dates[rangeIndex] : null
}

export function removeCampaignsWithInvalidDates(campaign: Campaign): boolean {
  return Boolean(getLiveDateRange(campaign.dates))
}

export function hasBeenPublished(dates: DateRange[]): boolean {
  if (!dates) {
    return false
  }
  // if the first start date in the first item within
  // the campaign's date ranges is past now, this has been published
  return new Date(dates[0].starts) <= new Date()
}

export function acronymize(str: string): string {
  return str
    ?.match(/\b([A-Z])/g)
    .join("")
    .toLowerCase()
}

export function getImgIXUrl(href: string, size: string): string {
  // https://s3.amazonaws.com/ligonier-public-media/store/product_variation_images_3d/EVE02_book_3d_trimmed.png;
  var params = ""
  if (size == "small") params = "?fm=jpg&fit=max&w=150&h=200"
  if (size == "medium") params = "?fm=jpg&fit=max&w=300&h=400"
  if (size == "large") params = "?fm=jpg&fit=max&w=610&h=500"
  return href == "" || !href
    ? ""
    : href.replace(
        "https://s3.amazonaws.com/ligonier-public-media",
        "https://ligonier.imgix.net"
      ) + params
}

export function isDigital(campaign) {
  return (
    campaign.offer.products.length == 1 &&
    getProductFormat(campaign.offer.products[0].format).toLowerCase() ==
      "download"
  )
}

export function getHero(campaign) {
  return isBundle(campaign) || !campaign || !campaign.offer
    ? false
    : campaign.offer.products[0]
}

export function getHeroImg(campaign) {
  var hero = campaign.offer.products[0]
  if (isDigital(campaign)) {
    return getImgIXUrl(campaign.offer.image || hero.image.flat || "", "large")
  } else {
    return getImgIXUrl(
      campaign.offer.image || hero.image.three_dimensional || "",
      "large"
    )
  }
}

export function getDisclaimer(campaign, shippable, downloadable) {
  if (shippable && campaign.offer.new_users_only) {
    return "Available in US or Canada for those new to Ligonier. One offer per household."
  } else if (shippable) {
    return "Available in US or Canada. One offer per household."
  } else if (campaign.offer.new_users_only) {
    return "Available for those new to Ligonier. One offer per household."
  } else if (campaign.offer.bundle) {
    return "One offer per unique code."
  } else if (shippable === false && downloadable === true) {
    return ""
  } else {
    return "One offer per household."
  }
}

export function getBadge(product: Product, campaign?: Campaign | undefined) {
  if (campaign && campaign.offer && campaign.offer.badge) {
    return campaign.offer.badge
  } else if (campaign && campaign.offer && campaign.offer.free) {
    return "FREE"
  } else if (product && product.format == "USB Drive") {
    if (["Teaching Series", "Conference Messages"].includes(product.type)) {
      return (
        "<em>" +
        product.messages.length +
        " Message</em> " +
        getProductType(product.type) +
        " on " +
        getProductFormat(product.format)
      )
    }

    if (product.type == "Bundle") {
      return (
        "<em>resource bundle on " + getProductFormat(product.format) + "</em>"
      )
    } else {
      return (
        "<em>" +
        getProductType(product.type) +
        "</em> on " +
        getProductFormat(product.format)
      )
    }
  } else if (
    product &&
    ["Teaching Series", "Conference Messages"].includes(product.type)
  ) {
    if (product.messages.length) {
      if (product.format == "Download") {
        return (
          "<em>" +
          product.messages.length +
          " Message</em> " +
          getProductType(product.type) +
          " Download"
        )
      } else {
        return (
          "<em>" +
          product.messages.length +
          " Message</em> " +
          getProductFormat(product.format) +
          " " +
          getProductType(product.type)
        )
      }
    } else {
      return getProductFormatAndType(product)
    }
  } else if (product && product.variations && product.variations.length) {
    var formats = getProductVariationFormats(product)
    if (isEbook(product.variations[0].format)) {
      return "Ebook (" + formats + ")"
    } else {
      return formats
    }
  } else if (product && product.type == "Music") {
    return (
      capitalize(getProductType(product.type)) +
      " " +
      capitalize(getProductFormat(product.format))
    )
  } else if (
    product &&
    ["book", "bible"].includes((product.type || "").toLowerCase())
  ) {
    if (isEbook(product.format)) {
      return getProductFormat(product.format)
    } else if (product.format.toLowerCase() == "audiobook cd") {
      return capitalize(getProductFormat(product.format))
    } else {
      return (
        "<em>" +
        product.length +
        " page</em> " +
        capitalize(getProductFormat(product.format)) +
        " " +
        capitalize(getProductType(product.type))
      )
    }
  } else {
    return ""
  }
}

export function getPreface(campaign: Campaign) {
  if (["481", "482"].includes(campaign.id)) return ""
  var preposition = campaign.brand == "Ministry Partners" ? "for" : "From"
  return campaign.offer
    ? campaign.offer.free
      ? "A Special Gift " + preposition
      : "A Special Offer " + preposition
    : campaign.language === "es-US"
    ? "Apoyando A"
    : "Supporting"
}

export function getIndividualFairMarketValue(product) {
  let total = 0

  if (product.variations) {
    product.variations.forEach(function (variation) {
      total += parseInt(variation.fair_market_value)
    })
  } else {
    total += parseInt(product.fair_market_value)
  }

  return total
}

export function getFairMarketValue(campaign: Campaign) {
  let total = 0

  if (campaign.offer && !campaign.offer.free) {
    campaign.offer.products.forEach(function (product) {
      total += getIndividualFairMarketValue(product)
    })
  }

  return total.toFixed(2)
}

export function getMPBenefitsFairMarketValue(campaign: Campaign) {
  // Monthly FMV total of $9.58 is $7.50 for streaming and $2.08 for Tabletalk
  return (brandHasStreamingBenefit(campaign.brand) ? 7.50 : 0.00) + 2.08
}

export function getHeadline(campaign) {
  if (campaign.headline) {
    return campaign.headline
  } else if (campaign.offer && campaign.offer.free) {
    if (campaign.offer.allowed_products) {
      return (
        "Yes, I Would Like " +
        campaign.offer.allowed_products +
        " of These Free Resources"
      )
    } else if (campaign.offer.products.length == 1) {
      return "Yes, I Would Like This Free Resource"
    } else {
      return "Yes, I Would Like These Free Resources"
    }
  } else {
    return "I Would Like to Give"
  }
}

export function isShippable(campaign) {
  var shippable = false
  if (campaign.offer) {
    shippable = campaign.offer.products.some(function (product) {
      return product.shippable
    })
  }
  return shippable
}

export function isFreeOffer(campaign) {
  return campaign && campaign.offer && campaign.offer.free
}

export function isDownloadable(product) {
  if (!product) return

  var variationDownloadable
  if (product.variations) {
    variationDownloadable = product.variations.some(function (variation) {
      return variation.downloadable === true
    })
  }
  return product.downloadable === true || variationDownloadable === true
}

export function getProductsList(products, toList) {
  var list = products.map(function (product, p) {
    var firstWord = product.name.split(" ")[0].toLowerCase()
    var addThe = !toList && !["the", "tabletalk"].includes(firstWord)
    var line =
      (addThe ? "the " : "") +
      "<em>" +
      product.name +
      "</em> " +
      getProductFormatAndType(product)
    var teachers = product.teachers

    if (teachers.length <= 2 && teachers != "Various Authors") {
      line += " by " + toReadable(teachers)
    }

    if (toList) {
      if (isDownloadable(product)) {
        line += " <span>(Available Now)</span>"
      }

      line = "<li>" + line + "</li>"
    }

    return line
  })

  return toList ? list.join(" ") : toReadable(list)
}

export function getCopy(campaign) {
  if (campaign.copy) {
    return campaign.copy
  }

  if (isFreeOffer(campaign)) {
    if (!campaign.offer.allowed_products) {
      if (campaign.offer.bundle) {
        return `Your copy of <em>${
          campaign.offer.bundle.purchased_product.name
        }</em> came with a unique code. Fill out this form to unlock ${getProductsList(
          campaign.offer.products,
          null
        )}.`
      } else {
        var verb = isShippable(campaign) ? "send" : "give"
        return `We would love to ${verb} you ${getProductsList(
          campaign.offer.products,
          null
        )}.`
      }
    }
  }

  if (!campaign.offer) {
    return `To support the ${campaign.purpose} of ${campaign.brand}.`
  } else if (campaign.offer && campaign.offer.allowed_products) {
    var str = ""
    if (!isFreeOffer(campaign)) {
      var resourcePlurality =
        campaign.offer.allowed_products == 1 ? "resource" : "resources"
      str = `Toward ${campaign.offer.allowed_products} ${resourcePlurality} to support the ongoing outreach of <em>${campaign.brand}</em>.`
    }
    var selectionPlurality =
      campaign.offer.allowed_products == 1 ? "selection" : "selections"
    str += `Make your ${selectionPlurality} below:`
    return str
  } else if (campaign.offer.products.length) {
    if (!isFreeOffer(campaign)) {
      var nameDrop =
        campaign.brand == "Renewing Your Mind" ? "and Ligonier Ministries," : ""
      return `To support the ongoing outreach of <em>${
        campaign.brand
      }</em> ${nameDrop} and receive ${getProductsList(
        campaign.offer.products,
        null
      )}.`
    }
  }
}

export function getQuote(campaign: Campaign): string {
  if (campaign.quote) {
    return campaign.quote
  } else if (campaign.offer) {
    return campaign.offer.free
      ? process.env.NEXT_PUBLIC_QUOTE_FREE
      : process.env.NEXT_PUBLIC_QUOTE_GOAA
  } else {
    return process.env.NEXT_PUBLIC_QUOTE_GOAA
  }
}

export function getQuoteBy(campaign: Campaign): string {
  return campaign.quote_by || process.env.NEXT_PUBLIC_SIGNER
}

export function getShareHref(campaign: Campaign, network: string): string {
  const domain = process.env.NEXT_PUBLIC_HOST
  var url = [domain, campaign.id, campaign.slug, "offer"].join("/")
  switch (network) {
    case "facebook":
      var href = "https://www.facebook.com/dialog/share?app_id="
      href += process.env.NEXT_PUBLIC_FACEBOOK_ID
      href += "&display=popup&href="
      href += url
      href += "&redirect_uri="
      href += url
      return href
    case "twitter":
      var href =
        "https://twitter.com/intent/tweet?text=Check out this offer from "
      href += process.env.NEXT_PUBLIC_TWITTER_HANDLE
      href += "&url="
      href += url
      return href
    case "email":
      var href = "mailto:?subject=Check out this offer from "
      href += campaign.brand
      href += "&body="
      href += url
      return href
    default:
      return "#"
  }
}

export function truncate(string: string, max: number, trail: string, atChar) {
  if (!string) return ""
  if (trail == null && arguments.length == 1) trail = "…"
  if (string.length > max) {
    string = string.substring(0, max)
    if (atChar) {
      var index = string.lastIndexOf(atChar)
      if (index != -1) string = string.substr(0, index)
    }
    if (trail) string += trail
  }
  return string
}

function getRegexForTag(tag?, contents?) {
  tag = tag || (contents ? "" : "\\w+")
  var regstr = contents
    ? "<" + tag + "(?!\\w)[^>]*>([\\s\\S]*?)</" + tag + "(?!\\w)>"
    : "</?" + tag + "/?>|<" + tag + "[\\s|/][^>]*>"
  return new RegExp(regstr, "gi")
}

export function stripTags(string, tag?, contents?) {
  return string?.replace(getRegexForTag(tag, contents), "")
}

export function getCampaignType(campaign, transaction) {
  var type = campaign.offer
    ? campaign.offer.free
      ? "Free Offer"
      : "GOAA"
    : "Donation"
  if (transaction.donation.recurring) type = "Recurring Donation"
  if (transaction.donation.ministry_partner === true)
    type = "Ministry Partners Donation"
  return type
}

export function getEmailSubject(campaign, transaction) {
  var type = campaign.offer
    ? campaign.offer.free
      ? "FREE"
      : "GOAA"
    : "DONATION"
  if (transaction.donation.recurring) type = "RECURRING"
  if (transaction.donation.ministry_partner === true) type = "PARTNER"

  switch (type) {
    case "GOAA":
      return "Thank you for your gift"
    case "DONATION":
      return campaign.language === "es-US"
        ? "Gracias por tu donación"
        : "Thank you for your donation"
    case "RECURRING":
      return "Thank you for your support"
    case "PARTNER":
      return "Thank you for partnering with us"
    default:
      // FREE
      return "Thank you"
  }
}

export function hasShippable(products, productCodes) {
  var order = products.filter(function (product) {
    if (product.variations) {
      var variationMatches = product.variations.some(function (variation) {
        return productCodes.includes(variation.code)
      })
    }
    return variationMatches || productCodes.includes(product.code)
  })

  var shippable = order.some(function (product) {
    if (product.variations) {
      var variationsShippable = product.variations.some(function (variation) {
        return variation.shippable
      })
    }
    return variationsShippable || product.shippable
  })

  return shippable
}

export const getProductCodes = (campaign: Campaign): string[] => {
  const productCodes = []

  if (campaign.offer && !campaign.offer.allowed_products) {
    campaign.offer.products.forEach((p) => {
      if (p.variations) {
        p.variations.forEach((v) => {
          productCodes.push(v.code)
        })
      } else {
        productCodes.push(p.code)
      }
    })
  }

  return productCodes
}

export const brandHasStreamingBenefit = (brand: string) => {
  return brand !== "Ligonier Ministries Canada"
}

type AlertTextKey =
  | "CAPTCHA_NOT_VALID"
  | "BLUEFIN_INPUT_NOT_VALID"
  | "BLUEFIN_PAYMENT_FAILURE"

export const alertUser = ({
  key,
  lang
}: {
  key: AlertTextKey
  lang: CampaignLanguageEnum
}) => {
  const defaultLang = "en-US"

  if (lang === "es-US") {
    return alert(alertText[key][lang])
  } else {
    return alert(alertText[key][defaultLang])
  }
}

// This data structure requires that there will always be
// a "en-US" language option field on each key.
const alertText = {
  BLUEFIN_INPUT_NOT_VALID: {
    "es-US": "Por favor verifica los datos de tu tarjeta",
    "en-US": "Please verify your credit card information"
  },
  BLUEFIN_PAYMENT_FAILURE: {
    "es-US":
      "Hubo un problema durante el procesamiento, por favor intenta de nuevo",
    "en-US": "There was a problem processing the request, please try again"
  },
  CAPTCHA_NOT_VALID: {
    "es-US":
      "Hubo un problema durante el procesamiento, por favor intenta de nuevo",
    "en-US": "There was a problem processing the request, please try again"
  }
}

export const cleanAmount = (amount?: string) => {
  if (!amount) return amount
  const re = /^\s*\$?\s*(?<amount>[0-9]+(\.[0-9]+)?)\s*$/
  const match = amount.toString().match(re)
  return match ? match.groups.amount : amount
}

export const isUSTerritory = (countryCode: string) =>
  countries.find((country) => country.code === countryCode)?.is_us_territory
