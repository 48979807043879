import {
  acronymize,
  brandHasStreamingBenefit,
  getLiveDateRange,
  getProductsList,
  stripTags,
  truncate
} from "./helpers"
import { countries } from "./support/countries"
import { getReferralSources } from "./support/referralSources"

export function mapCampaign(campaign) {
  var isBundle = campaign.offer && campaign.offer.products.length > 1
  var shippable = false
  var downloadable = false
  var productsWithVariations = []

  if (campaign.offer) {
    productsWithVariations = campaign.offer.products.filter(function (product) {
      return product.hasOwnProperty("variations")
    })

    if (productsWithVariations.length > 0) {
      shippable = productsWithVariations.some(function (product) {
        return product.variations.some(function (variation) {
          return variation.shippable
        })
      })

      downloadable = productsWithVariations.some(function (product) {
        return product.variations.some(function (variation) {
          return variation.downloadable
        })
      })
    }

    shippable = shippable || campaign.offer.products.some(function (product) {
      return product.shippable
    })

    downloadable = downloadable || campaign.offer.products.some(function (product) {
      return product.downloadable
    })
  }

  const data = {
    description: truncate(stripTags(campaign.description), 170, "…", " "),
    year: new Date().getFullYear(),
    env: process.env.NEXT_PUBLIC_ENV,
    captchaPublicKey: process.env.NEXT_PUBLIC_RECAPTCHA_PUBLIC_KEY,
    segmentKey: process.env.SEGMENT_KEY,
    brand: campaign.brand,
    downloadable: downloadable,
    shippable: shippable,
    countries: shippable ? [countries[0], countries[1]] : countries,
    free: campaign && campaign.offer && campaign.offer.free,
    hero:
      isBundle || !campaign || !campaign.offer
        ? false
        : campaign.offer.products[0],
    campaign: campaign,
    transaction: undefined,
    recur_day: undefined,
    recur_start_date: undefined,
    orderedProducts: undefined,
    transactionHasShipping: undefined,
    channels: getReferralSources(acronymize(campaign.brand)),
    helpers: {
      getProductsList, brandHasStreamingBenefit
    }
  }

  var range = getLiveDateRange(campaign.dates)
  if (range) {
    data.campaign.starts = range.starts
    data.campaign.ends = range.ends
  }

  return data
}
